import "./utils/globals.css";
import Rotas from "../src/routes";
// import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer } from "react-toastify";
// import { io } from 'socket.io-client';

// const socket = io('20.75.112.13:8080');
function App() {
  return (
      <Rotas />
  );
}

export default App;
