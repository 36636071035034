import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from './InfoPerfilEmpresa.module.css';
import IconeEndereco from "../../utils/assets/icones/navigationIcon.png";
import Mapa from '../mapa/Mapa';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { getUsuario, getId } from '../../services/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api'
import { Try } from '@mui/icons-material';

const InfoPerfilEmpresa = ({ logo, nomeEmpresa, breveDesc, endereco, descricao }) => {
    const [location, setLocation] = useState(null);
    const [telefone, setTelefone] = useState('11942064580');
    const [avaliacao, setAvaliacao] = useState("");
    const [comentario, setComentario] = useState('');
    const [isEditingNome, setIsEditingNome] = useState(false);
    const [editedNomeEmpresa, setEditedNomeEmpresa] = useState(nomeEmpresa);
    const [isEditingDesc, setIsEditingDesc] = useState(false);
    const [editedBreveDesc, setEditedBreveDesc] = useState(breveDesc);
    const [isEditingDescricao, setIsEditingDescricao] = useState(false);
    const [editedDescricao, setEditedDescricao] = useState(descricao);
    const [newLogo, setNewLogo] = useState(null); // Estado para a nova logo
    const [logoUrl, setLogoUrl] = useState(logo); // URL atual da logo

    const message = 'Oi, Estou interessada(o) pelo seu trabalho!';
    const usuario = getUsuario();
    const id = getId();
    const url = window.location.pathname; 
    const IdEmpresa = url.substring(url.lastIndexOf("/") + 1); 
    
    const handleSubmitEdit = async (event) => {
        event.preventDefault();

        const data = {
            nome: editedNomeEmpresa,
            descricao: editedDescricao,
            breveDesc: editedBreveDesc,
            fotoPerfil: logoUrl // Inclui a URL da logo no objeto de dados
        };

        try {
            const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                toast.error('Erro ao atualizar informações');
            }

            toast.success('Dados atualizados com sucesso!');
        } catch (error) {
            console.error('Erro ao atualizar os dados:', error);
            toast.error('Erro ao atualizar os dados.');
        }
    };

    // Função para fazer upload da nova imagem para o Cloudinary
    const handleLogoChange = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            toast.error('Nenhuma imagem selecionada');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'uploadImagens'); // Substitua com seu preset

        try {
            const response = await fetch(`https://api.cloudinary.com/v1_1/dryvtyiwk/upload`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.secure_url) {
                setLogoUrl(data.secure_url); // Atualiza a URL da logo com o link retornado pelo Cloudinary
                toast.success('Logo atualizada com sucesso!');
            } else {
                toast.error('Erro ao enviar a imagem. Tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao enviar a imagem:', error);
            toast.error('Erro ao enviar a imagem. Tente novamente.');
        }
    };

    const handleClickWhatsapp = () => {
        const url = `https://wa.me/${telefone}?text=${encodeURIComponent(message)}`;

        window.open(url, '_blank');
    };

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const encodedAddress = encodeURIComponent(endereco);
                const apiKey = 'SUA_API_KEY';
                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`);

                if (response.data.results[0]) {
                    console.log("Geocode response:", response.data.results[0]);
                    setLocation(response.data.results[0].geometry.location);
                } else {
                    console.error("Sem resultados para o endereço");
                }
            } catch (error) {
                console.error("Error fetching location:", error);
            }
        };

        fetchLocation();
    }, [endereco]);

    const handleSubmit = () => {
        console.log('Avaliação:', avaliacao);
        console.log('Comentário:', comentario);
        let excelente = 0;
        let bom = 0;
        let regular = 0;
        let ruim = 0;
        let pessimo = 0;

        if(avaliacao === "Excelente") {
            excelente = + 1
        }else if(avaliacao === "Bom") {
            bom = + 1
        }else if(avaliacao === "Regular") {
            regular = + 1
        }else if(avaliacao === "Ruim") {
            ruim = + 1
        }else if(avaliacao === "Pessimo") {
            pessimo = + 1
        }

        const dadosAvaliacao = {
            excelente: excelente,
            bom: bom,
            regular: regular,
            ruim: ruim,
            pessimo: pessimo
        }
        console.log('Dados Avaliação:', dadosAvaliacao);

        try {
        const response = fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/usuario/${id}/avaliacao`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                avaliacao: avaliacao,
                comentario: comentario,
                empresaId: IdEmpresa
            })
        });
            toast.success('Avaliação enviada com sucesso!');
        }catch (error) {
            toast.error('Erro ao enviar avaliação', error);
        }
            const response = fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${IdEmpresa}/avaliacoes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    excelente: excelente,
                    bom: bom,
                    regular: regular,
                    ruim: ruim,
                    pessimo: pessimo
                })
            });

        setComentario('');
    };

    return (
        <div id={style["container"]}>
            <section id={style["areaEmpresaTopo"]}>
                <div id={style["divLogo"]}>
                    <img src={logoUrl} alt="" />
                    {usuario === "empresa" && (
                        <>
                            <EditIcon
                                className={style.iconeEditar}
                                style={{ marginLeft: "8px", paddingLeft: "100%" }}
                                onClick={() => document.getElementById('logoInput').click()} // Clique no campo de upload
                            />
                            <input
                                type="file"
                                id="logoInput"
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={handleLogoChange}
                            />
                        </>
                    )}
                </div>
                <div id={style["areaDesc"]}>
                    <div id={style["nomeEmpresa"]}>
                        {isEditingNome ? (
                            <>
                                <input
                                    className={style.inputNomeEmpresa}
                                    type="text"
                                    value={editedNomeEmpresa}
                                    onChange={(e) => setEditedNomeEmpresa(e.target.value)}
                                />
                                <SaveIcon
                                    className={style.iconeSalvar}
                                    onClick={(e) => {
                                        setIsEditingNome(false);
                                        handleSubmitEdit(e);
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                {editedNomeEmpresa}{" "}
                                {usuario === "empresa" && (
                                    <EditIcon
                                        className={style.iconeEditar}
                                        onClick={() => setIsEditingNome(true)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <div className={style.breveDesc} id={style["BreveDesc"]}>
                        {isEditingDesc ? (
                            <>
                                <textarea
                                    value={editedBreveDesc}
                                    onChange={(e) => setEditedBreveDesc(e.target.value)}
                                    className={style.textAreaBrevDesc}
                                />
                                <SaveIcon
                                    className={style.iconeSalvar}
                                    onClick={(e) => {
                                        setIsEditingDesc(false);
                                        handleSubmitEdit(e);
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                {editedBreveDesc}{" "}
                                {usuario === "empresa" && (
                                    <EditIcon
                                        className={style.iconeEditar}
                                        onClick={() => setIsEditingDesc(true)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div id={style["endereco"]}>
                    <div>
                        <img src={IconeEndereco} alt="icone para simbolizar a busca do endereço" />
                    </div>
                    <p>{endereco}</p>
                </div>
            </section>
            <section id={style["areaBaixo"]}>
                <div id={style["descricao"]}>
                    {usuario === "cliente" && (
                        <div id={style["btnChat"]}>
                            <button onClick={handleClickWhatsapp}>Chat</button>
                        </div>
                    )}
                    <div  id={style["descricaoEmpresa"]}>
                        {isEditingDescricao ? (
                            <>
                                <textarea
                                    value={editedDescricao}
                                    onChange={(e) => setEditedDescricao(e.target.value)}
                                    className={style.textAreaDesc}
                                />
                                <SaveIcon
                                    className={style.iconeSalvar}
                                    onClick={(e) => {
                                        setIsEditingDescricao(false);
                                        handleSubmitEdit(e);
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                {editedDescricao}{" "}
                                {usuario === "empresa" && (
                                    <EditIcon
                                        className={style.iconeEditar}
                                        onClick={() => setIsEditingDescricao(true)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div id={style["mapa"]}>
                    {location ? <Mapa endereco={location} /> : <p>Loading location...</p>}
                </div>
            </section>
            <section>
                <div>
                    <p>Faça sua avaliação!</p>
                </div>
                <div className={style.areaAvaliacao}>
                    <div className={style.rating}>
                        <input 
                            type="radio"
                            id="estrela5"
                            name="avaliar"
                            value={"Excelente"}
                            onChange={(e) => setAvaliacao(e.target.value)}
                        />
                        <label title="text" htmlFor="estrela5"></label>

                        <input 
                            type="radio"
                            id="estrela4"
                            name="avaliar"
                            value={"Bom"}
                            onChange={(e) => setAvaliacao(e.target.value)}
                        />
                        <label title="text" htmlFor="estrela4"></label>

                        <input 
                            type="radio"
                            id="estrela3"
                            name="avaliar"
                            value={"Regular"}
                            onChange={(e) => setAvaliacao(e.target.value)}
                        />
                        <label title="text" htmlFor="estrela3"></label>

                        <input 
                            type="radio"
                            id="estrela2"
                            name="avaliar"
                            value={"Ruim"}
                            onChange={(e) => setAvaliacao(e.target.value)}
                        />
                        <label title="text" htmlFor="estrela2"></label>

                        <input 
                            type="radio"
                            id="estrela1"
                            name="avaliar"
                            value={"Pessimo"}
                            onChange={(e) => setAvaliacao(e.target.value)}
                        />
                        <label title="text" htmlFor="estrela1"></label>
                    </div>
                </div>
                <div className={style.areaComentario}>
                    <h3>Deixe seu comentário</h3>
                    <textarea 
                        className={style.textareaComentario} 
                        placeholder="Escreva algo sobre sua experiência..."
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)} 
                    />
                    <button 
                        className={style.botaoEnviarComentario}
                        onClick={handleSubmit} 
                    >
                        Enviar Comentário
                    </button>
                </div>
            </section>
        </div>
    );
};

export default InfoPerfilEmpresa;
