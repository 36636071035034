import React, { useEffect, useState } from 'react';
import styles from './AvaliacaoCliente.module.css';
import api from "../../api";
import { getId } from '../../services/auth';

const AvaliacaoCliente = () => {
  const [excelente, setExcelente] = useState(0);
  const [bom, setBom] = useState(0);
  const [regular, setRegular] = useState(0);
  const [ruim, setRuim] = useState(0);
  const [pessimo, setPessimo] = useState(0);
  const [totalAvaliacoes, setTotalAvaliacoes] = useState(0);
  const [media, setMedia] = useState(0);

  // Função para buscar as avaliações
  useEffect(() => {
    const fetchAvaliacoes = async () => {
      const id = getId();
      let excelenteCount = 0
      let bomCount = 0
      let regularCount = 0
      let ruimCount = 0
      let pessimoCount = 0
      try {

        const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}/avaliacoes`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
        })
        .then((response) => response.json()) // Converte a resposta para JSON
        .then((data) => {
          if (data) {
            console.log(data)
            console.log('avaliacoes',data.length)
            
            setTotalAvaliacoes(data.length);
        
        // Percorrer o array de avaliações e contar por tipo
        for (let i = 0; i < data.length; i++) {


          const avaliacaoExcelente = data[i].excelente; // Supondo que o campo que contém a avaliação seja "avaliacao"
          const avaliacaoBom = data[i].bom;
          const avaliacaoRegular = data[i].regular;
          const avaliacaoRuim = data[i].ruim;
          const avaliacaoPessimo = data[i].pessimo;
        
          if (avaliacaoExcelente) {
            excelenteCount++;
          }
          if (avaliacaoBom) {
            bomCount++;
          }
          if (avaliacaoRegular) {
            regularCount++;
          }
          if (avaliacaoRuim) {
            ruimCount++;
          }
          if (avaliacaoPessimo) {
            pessimoCount++;
          }

        }
          }
        })
        .catch((e) => {
          console.error("Houve um erro ao buscar a empresa: ", e);
        });
        // const response = await api.get('/avaliacoes{id}');
        // const data = response.data.avaliacoes;

        // Obtenção das quantidades de cada tipo de avaliação

        // Atualiza o estado das avaliações
        setExcelente(excelenteCount);
        setBom(bomCount);
        setRegular(regularCount);
        setRuim(ruimCount);
        setPessimo(pessimoCount);

        const total = excelenteCount + bomCount + regularCount + ruimCount + pessimoCount;
        setTotalAvaliacoes(total);

        const mediaCalculada = (
          (excelenteCount * 5 + bomCount * 4 + regularCount * 3 + ruimCount * 2 + pessimoCount * 1) /
          total
        );
        setMedia(mediaCalculada);
      } catch (error) {
        console.error('Erro ao buscar as avaliações:', error);
      }
    };

    fetchAvaliacoes();
  }, []);

  // Função para renderizar as estrelas com base na média
  const renderEstrelas = (media) => {
    const estrelas = [];
    const cheio = Math.floor(media); // Quantas estrelas cheias
    const meia = media % 1 > 0 ? 1 : 0; // Verifica se há meia estrela

    for (let i = 0; i < 5; i++) {
      if (i < cheio) {
        estrelas.push('★'); // Estrela cheia
      } else if (i === cheio && meia) {
        estrelas.push('☆'); // Estrela meia
      } else {
        estrelas.push('☆'); // Estrela vazia
      }
    }

    return estrelas.join('');
  };

  return (
    <div className={styles["avaliacao-cliente"]}>
      <div className={styles["avaliacao-title"]}>
        <h3>Avaliações de clientes</h3>
        <div className={styles["estrelas"]}>
          <span>{renderEstrelas(media)}</span> {/* Renderiza as estrelas com base na média */}
          <span>{media.toFixed(1)}</span> {/* Exibe a média com 1 casa decimal */}
        </div>
        <p>Classificação geral de {totalAvaliacoes} avaliações de clientes</p>
      </div>
      <div className={styles["avaliacao-barras"]}>
        <div className={styles["avaliacao-barra"]} style={{ width: "100%" }}>
          <p className={styles["avaliacao-label"]}>Excelente</p>
          <p className={styles["avaliacao-numero"]}>{excelente}</p>
        </div>
        <div className={styles["avaliacao-barra"]} style={{ width: "100%" }}>
          <p className={styles["avaliacao-label"]}>Bom</p>
          <p className={styles["avaliacao-numero"]}>{bom}</p>
        </div>
        <div className={styles["avaliacao-barra"]} style={{ width: "100%" }}>
          <p className={styles["avaliacao-label"]}>Regular</p>
          <p className={styles["avaliacao-numero"]}>{regular}</p>
        </div>
        <div className={styles["avaliacao-barra"]} style={{ width: "100%" }}>
          <p className={styles["avaliacao-label"]}>Ruim</p>
          <p className={styles["avaliacao-numero"]}>{ruim}</p>
        </div>
        <div className={styles["avaliacao-barra"]} style={{ width: "100%" }}>
          <p className={styles["avaliacao-label"]}>Péssimo</p>
          <p className={styles["avaliacao-numero"]}>{pessimo}</p>
        </div>
      </div>
    </div>
  );
};

export default AvaliacaoCliente;
