import style from './Servicos.module.css';
import Logo from "../../utils/assets/logo.svg";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSnowflake, faShirt, faChampagneGlasses, faComputer, faHouse, faSearch } from "@fortawesome/free-solid-svg-icons";
import NavBar from '../../components/navBar/NavBar';
import CardServico from '../../components/cardServico/CardServico';
import CardRanking from '../../components/ranking/Ranking';
import CarrousselServices from '../../components/carrosselService/CarrosselServices';
import { getUsuario, getId } from '../../services/auth';
import api from "../../api"; // Importando o Axios configurado
import axios from 'axios'; // Importando o Axios para requisições adicionais

const Servicos = ({ onFilterChange }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [servicos, setServicos] = useState([]);
    const [rankingServicos, setRankingServicos] = useState([]);
    const [filteredServicos, setFilteredServicos] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [dados, setDados] = useState([]);
    const id = getId();

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (onFilterChange) {
            onFilterChange(e.target.value);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        console.log(`Categoria selecionada: ${category}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/servico`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                  })
                  console.log('response',response)
                if (!response.ok) {
                    throw new Error('Erro ao buscar dados da Mock API');
                }
                if (response.status !== 200) {
                    throw new Error('Erro ao buscar dados da Outra API');
                }


                console.log('Dados da Outra API:', response);
                const data = await response.json();

                const cleanedDataApi = data.map(apiItem => ({
                    id: apiItem.id,  
                    avatar: apiItem.avatar,
                    titulo: apiItem.titulo,
                    categoria: apiItem.categoria,
                    descricaoServico: apiItem.descricaoServico,
                    nota: apiItem.nota,
                    empresaId: apiItem.empresaId,
                }));    
                console.log("CleanedData", cleanedDataApi)
                
                setServicos(cleanedDataApi);
                setFilteredServicos(cleanedDataApi);
                const rankedServices = cleanedDataApi.filter(servico => servico.nota >= 4.5);
                setRankingServicos(rankedServices.slice(0, 5)); // Limit to top 5 services
            } catch (error) {
                console.error("Erro ao buscar dados das APIs", error);
                toast.error("Erro ao buscar dados das APIs");
            }
        };

        fetchData();
    }, []);
console.log(filteredServicos)
    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filtered = servicos.filter(servico => {
            const matchesCategory = selectedCategory === "" || servico.categoria === selectedCategory;
            const matchesSearchTerm =
                servico.titulo.toLowerCase().includes(lowercasedFilter) ||
                servico.categoria.toLowerCase().includes(lowercasedFilter);
            return matchesCategory && matchesSearchTerm;
        });
        console.log(`Serviços filtrados para categoria ${selectedCategory} e termo de busca "${searchTerm}":`, filtered);
        console.log('filtered:', filtered);
        setFilteredServicos(filtered);
    }, [searchTerm, selectedCategory, servicos]);

    useEffect(() => {
        window.onload = function () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };
    }, []);

    console.log('Usuario logado:', getUsuario());

    return (
        <main id={style["principalServicos"]}>
            <NavBar logoInicio={Logo} />
            <section id={style["filtro"]}>
                <div className={style["topicosFiltro"]}>
                    <ul>
                        <div onClick={() => handleCategoryClick("Refrigeração")}>
                            <li className={style["filterBtn"]}>Refrigeração</li>
                            <FontAwesomeIcon icon={faSnowflake} className={style["ciano"]} />
                        </div>
                        <div onClick={() => handleCategoryClick("Moda")}>
                            <li className={style["filterBtn"]}>Moda</li>
                            <FontAwesomeIcon icon={faShirt} className={style["laranja"]} />
                        </div>
                        <div onClick={() => handleCategoryClick("Eventos")}>
                            <li className={style["filterBtn"]}>Eventos</li>
                            <FontAwesomeIcon icon={faChampagneGlasses} className={style["ciano"]} />
                        </div>
                        <div onClick={() => handleCategoryClick("Tecnologia")}>
                            <li className={style["filterBtn"]}>Tecnologia</li>
                            <FontAwesomeIcon icon={faComputer} className={style["laranja"]} />
                        </div>
                        <div onClick={() => handleCategoryClick("Domésticos")}>
                            <li className={style["filterBtn"]}>Domésticos</li>
                            <FontAwesomeIcon icon={faHouse} className={style["ciano"]} />
                        </div>
                    </ul>
                </div>
                <InputGroup className={style["searchInput"]}>
                    <FormControl
                        placeholder="Pesquisar serviços..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className={style["inputBusca"]}
                    />
                    <InputGroup.Text className={style["iconeBusca"]}>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                </InputGroup>
            </section>
            <aside id={style["bannerServicos"]}>
                <CarrousselServices />
            </aside>
            <section id={style["conteudoServicos"]}>
                <section id={style["areaServicos"]}>
                    {filteredServicos.map(servico => (
                        <CardServico
                            key={servico.id}
                            empresa={servico.empresaId}
                            imagemSrc={servico.avatar}
                            altText={`logo da empresa ${servico.nomeServico}`}
                            titulo={servico.titulo}
                            categoria={servico.categoria}
                            descricaoServico={servico.descricaoServico}
                            nota={servico.nota}
                            descricaoLimite={120}
                            styles={style}
                        />
                    ))}
                </section>
                <section id={style["rankingServicos"]} className={style["rankingServicos"]}>
                    <div className={style["divTitulo"]}>
                        <h3 className={style["tituloRankingServicos"]}>Ranking semanal</h3>
                    </div>
                    {rankingServicos.map(servico => (
                        <CardRanking
                            key={servico.id}
                            imagemSrc={servico.avatar}
                            altText={`logo da empresa ${servico.nomeServico}`}
                            titulo={servico.titulo}
                            categoria={servico.categoria}
                            nota={servico.nota}
                        />
                    ))}
                </section>
            </section>
            <ToastContainer />
        </main>
    );
};

export default Servicos;
