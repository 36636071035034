import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import styles from "./Dash.module.css";
import SideBar from "../../../components/sideBar/SideBar";
import Dashboard from "../../../components/dashboard/DashBoard";
import TopServices from "../../../components/topServicos/TopServicos";
import Avalicao from "../../../components/avaliacaoCliente/AvaliacaoCliente";
import Kpi from "../../../components/kpi/kpi";
import Lucro from "../../../utils/assets/icones/icones.svg";
import Prdutos from "../../../utils/assets/icones/icones-1.svg";
import Metas from "../../../utils/assets/icones/icones-2.svg";
import Conexoes from "../../../utils/assets/icones/icones-3.svg";
import { useNavigate } from 'react-router-dom';
import { getId } from '../../../services/auth';
import { useParams, Link } from 'react-router-dom';
import api from "../../../api";

// Registra os componentes necessários do Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dash = () => {
  const navigate = useNavigate();
  const id = getId();

  const [empresaNome, setEmpresaNome] = useState("");
  const [fotoPerfil, setFotoPerfil] = useState("");
  const [totalServicos, setTotalServicos] = useState(0);
  const [totalPublicacoes, setTotalPublicacoes] = useState(0);
  const [totalAvaliacoes, setTotalAvaliacoes] = useState(0);
  const [totalAcessos, setTotalAcessos] = useState(0);
  const [acessosDiarios, setAcessosDiarios] = useState([{dia: '2024-12-06', acessos: 5}, {dia: '2024-12-07', acessos: 3}, {dia: '2024-12-09', acessos: 8}]);

  // Carregar o nome da empresa
  useEffect(() => {
    const fetchEmpresaNome = async () => {
      const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
      })
      .then((response) => response.json()) // Converte a resposta para JSON
      .then((data) => {
        if (data) {
          console.log(data.nome)
          setEmpresaNome(data.nome)
          setFotoPerfil(data.fotoPerfil)
        }
      })
      .catch((e) => {
        console.error("Houve um erro ao buscar a empresa: ", e);
      });

    };
    fetchEmpresaNome();
  }, [id]);

  // Carregar os totais de serviços, publicações, e acessos
  useEffect(() => {
    const fetchTotalServicos = async () => {
      const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}/servicos`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
      })
      .then((response) => response.json()) // Converte a resposta para JSON
      .then((data) => {
        if (data) {
          console.log('servicos',data.length)
          setTotalServicos(data.length)
        }
      })
      .catch((e) => {
        console.error("Houve um erro ao buscar a empresa: ", e);
      });
      // api.get(`empresas/totalServicos/${id}`).then((response) => {
      //   setTotalServicos(response.data);
      // }).catch((error) => {
      //   console.error("Houve um erro ao buscar o total de serviços: " + error);
      // });
    };
    fetchTotalServicos();
  }, [id]);

  useEffect(() => {
    const fetchTotalPublicacoes = async () => {
      const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}/publicacao`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
      })
      .then((response) => response.json()) // Converte a resposta para JSON
      .then((data) => {
        if (data) {
          console.log('publicacoes',data.length)
          setTotalPublicacoes(data.length);
        }
      })
      .catch((e) => {
        console.error("Houve um erro ao buscar a empresa: ", e);
      });
      // api.get(`empresas/totalPublicacoes/${id}`,{
      //   headers: {
      //     'Authorization': `Bearer ${localStorage.getItem('token')}`
      //   }}
      // ).then((response) => {
      //   setTotalPublicacoes(response.data);
      // }).catch((error) => {
      //   console.error("Houve um erro ao buscar o total de publicações: " + error);
      // });
    };
    fetchTotalPublicacoes();
  }, [id]);

  useEffect(() => {
    const fetchTotalAvaliacoes = async () => {
      const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}/avaliacoes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
      })
      .then((response) => response.json()) // Converte a resposta para JSON
      .then((data) => {
        if (data) {
          console.log('avaliacoes',data.length)
          setTotalAvaliacoes(data.length);
        }
      })
      .catch((e) => {
        console.error("Houve um erro ao buscar a empresa: ", e);
      });
      // api.get(`empresas/totalAvaliacoes/${id}`).then((response) => {
      //   setTotalAvaliacoes(response.data);
      // }).catch((error) => {
      //   console.error("Houve um erro ao buscar o total de avaliações: " + error);
      // });
    };
    fetchTotalAvaliacoes();
  }, [id]);

  // Carregar os acessos diários
  // useEffect(() => {
  //   const fetchAcessosDiarios = async () => {
  //     api.get(`/acessos/diarios/${id}`).then((response) => {
  //       setAcessosDiarios(response.data);  // Exemplo de dados esperados: [{ dia: '2024-12-06', acessos: 5 }, ...]
  //     }).catch((error) => {
  //       console.error("Houve um erro ao buscar os acessos diários: " + error);
  //     });
  //   };
  //   fetchAcessosDiarios();
  // }, [id]);

  useEffect(() => {
    const fetchTotalServicos = async () => {
      api.get(`/totalAcessos/${id}`).then((response) => {
        setTotalAcessos(response.data);
      }).catch((error) => {
        console.error("Houve um erro ao buscar o total de serviços: " + error);
      });
    };
    fetchTotalServicos();
  }, [id]);

  const chartData = {
    labels: acessosDiarios.map((acesso) => acesso.dia), // Ex: ['2024-12-06', '2024-12-07', ...]
    datasets: [
      {
        label: 'Acessos',
        data: acessosDiarios.map((acesso) => acesso.acessos), // Ex: [5, 10, 7, ...]
        borderColor: '#64B5F6',
        backgroundColor: 'rgba(100, 181, 246, 0.2)',
        fill: true,
        tension: 0.4,
        pointRadius: 5, // Adiciona mais destaque aos pontos
        pointBackgroundColor: '#64B5F6',
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <main id={styles["containerDash"]}>
        <SideBar />
        <div className={styles["container"]}>
          <div className={styles["title"]}>
            <h2>Olá, {empresaNome}</h2>
          </div>
          <div className={styles["kpis-group"]}>
            <Kpi icon={Lucro} value={totalServicos} label="Servicos" />
            <Kpi icon={Prdutos} value={totalPublicacoes} label="Publicações" />
            <Kpi icon={Metas} value={totalAvaliacoes} label="Total Avaliações" />
            <Kpi icon={Conexoes} value={totalAcessos} label="Total acessos" />
          </div>
          <div className={styles["grafico-acessos"]}>
            <h3 className={styles["grafico-titulo"]}>Acessos Diários</h3>
            <div className={styles["grafico-container"]}>
              <Line data={chartData} options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    backgroundColor: '#333',
                    titleColor: '#fff',
                    bodyColor: '#fff',
                    footerColor: '#fff',
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    ticks: {
                      beginAtZero: true,
                      stepSize: 1,
                    },
                    grid: {
                      borderDash: [2],
                    },
                  },
                },
              }} />
            </div>
          </div>
        </div>
        <div className={styles["container2"]}>
          {/* <Link to={`/empresa/perfil/${id}`} className={styles["linkPerfil"]}>
            <div className={styles['logo-perfil']}>
              <div className={styles["profile"]}>
                <img src={fotoPerfil} alt="Imagem da logo do perfil" />
              </div>
              <div className={styles["meu-perfil"]}>
                <h2>Meu Perfil</h2>
              </div>
            </div>
          </Link> */}
          <div className={styles["avaliacao"]}>
            <Avalicao />
          </div>
        </div>
      </main>
    </>
  );
};

export default Dash;