import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardServico.module.css'; // Importe o arquivo de estilo aqui
import { Link } from 'react-router-dom';

const CardServico = ({ empresa, imagemSrc, altText, titulo, categoria, descricaoServico = "", nota, descricaoLimite }) => {
    // Lógica para truncar a descrição conforme o limite especificado
    const truncatedDescricao = descricaoServico.length > descricaoLimite ? `${descricaoServico.substring(0, descricaoLimite)}...` : descricaoServico;

    return (
        <div className={styles["servico"]}>
            <div className={styles["servicesTop"]}>
                <div className={styles["imgServico"]}>
                    <Link to={`/empresa/perfil/${empresa}`}>
                        <img src={imagemSrc} />
                    </Link>
                </div>
                <div className={styles["infosServico"]}>
                    <h4 className={styles["tituloServico"]}>{titulo}</h4>
                    <p className={styles["categoriaServico"]}>{categoria}</p>
                </div>
            </div>
            <div className={styles["servicesMid"]}>
                <p className={styles["descricaoServico"]}>{truncatedDescricao}</p>
            </div>
            <div className={styles["servicesBot"]}>
                <div id={styles["areaAvaliacao"]}>
                    <div> Avaliação: <span id={styles["notaAvaliacao"]}>{nota}</span></div>
                    <Link to="/" >
                        <span className={styles["orcamentoBtn"]}>Faça seu orçamento</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

CardServico.propTypes = {
    imagemSrc: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    titulo: PropTypes.string.isRequired,
    categoria: PropTypes.string.isRequired,
    descricaoServico: PropTypes.string,
    nota: PropTypes.string.isRequired,
    descricaoLimite: PropTypes.number.isRequired, // Limite para a descrição
};

export default CardServico;
