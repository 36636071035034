import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import style from "../navBar/NavBar.module.css";
import Logo from "../../utils/assets/logo.svg";
import ImagemPerfil from "../../utils/assets/iconUser.png";
import { HashLink as Link } from 'react-router-hash-link';
import { toast } from 'react-toastify';
import { getUsuario, getId } from '../../services/auth';
import { useParams } from 'react-router-dom';


const NavBar = ({ logoInicio }) => {
  const [empresaInfo, setEmpresaInfo] = useState(null);
  const [error, setError] = useState(false);
  const Navigate = useNavigate();
  const usuario = getUsuario();
  const { id } = useParams();
    const idLogada = getId();

  const handleSessionStorage = () => {
    sessionStorage.clear();
    Navigate('/');
  };

  const fetchEmpresaInfo = async () => {
    try {
      const response = await fetch('https://6605fc8ad92166b2e3c31978.mockapi.io/empresa/1'); // Assumindo que a logo está no objeto com ID 1
      if (!response.ok) {
        throw new Error('Erro ao buscar informações da empresa');
      }
      const data = await response.json();
      if (data.logo) {
        setEmpresaInfo(data);
      } else {
        throw new Error('Logo da empresa não encontrado');
      }
    } catch (error) {
      console.error('Erro ao buscar informações da empresa:', error);
      setError(true);
      toast.error('Erro ao buscar informações da empresa');
    }
  };

  useEffect(() => {
    fetchEmpresaInfo();
  }, []);

  const location = useLocation();
  const isServicosPage = location.pathname === "/servicos";
  const isPerfilPage = location.pathname.startsWith("/empresa/perfil");
  function handleLinkClick(event) {
    event.preventDefault();
    const id = event.currentTarget.getAttribute('href').substring(1);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn(`Element with id ${id} not found.`);
    }
  }

  return (
    <nav
      className={`${style["navBar"]} ${isServicosPage ? style["navBarServicos"] : ""
        } ${isPerfilPage ? style["navBarPerfil"] : ""}`}
    >
      <div className={style["logo"]}>
        <Link to="/">
          <img src={Logo} alt="logo WorkDoor" />
        </Link>
      </div>
      {error ? (
        <div className={style["imgPerfil"]}>
          <img src={ImagemPerfil} alt="Imagem do serviço" />
        </div>
      ) : (
        <>
          {isServicosPage ? (
            <div className={style["containerPerfil"]}>
              <div className={style["btnLogout"]}>
                <p onClick={handleSessionStorage}>sair</p>
              </div>
              <Link to={usuario === 'empresa' && idLogada === id ? `/empresa/configuracoes/${id}` : '#'}>
              <div className={style["imgPerfil"]}>
                  {empresaInfo && <img src={empresaInfo.avatar} alt="Imagem do serviço" />}
                </div>
              </Link>
            </div>
          ) : isPerfilPage ? (
            <>
              <div id={style["menuLista"]}>
                <ul>
                  <Link to="/#comoFunciona" className={style["btnLink"]}>
                    {" "}
                    <li>Inicio</li>{" "}
                  </Link>
                  <Link to="/servicos" className={style["btnLink"]}>
                    {" "}
                    <li>Serviços</li>{" "}
                  </Link>
                </ul>
              </div>
              <div className={style["containerPerfil"]}>
                <div className={style["btnLogout"]}>
                  <p onClick={handleSessionStorage}>sair</p>
                </div>
                <Link to={usuario === 'empresa' && idLogada === id ? `/dash` : '#'}>
                <div className={style["imgPerfil"]}>
                    {empresaInfo && <img src={"https://th.bing.com/th/id/R.c9efde2f7cab87270b7e32dc38362a10?rik=CzW2SZAUVyC6Ow&pid=ImgRaw&r=0"} alt="Imagem do serviço" />}
                  </div>
                </Link>
              </div>
            </>
          ) : (
            <>
              <div id={style["menuLista"]}>
                <ul>
                  <li className={style["btnLink"]}>
                    <a href="#ComoFunciona" onClick={handleLinkClick}>Como funciona</a>
                  </li>
                  <li className={style["btnLink"]}>
                    <a href="#Servicos" onClick={handleLinkClick}>Serviços</a>
                  </li>
                  <li className={style["btnLink"]}>
                    <a href="#SobreNos" onClick={handleLinkClick}>Sobre Nós</a>
                  </li>
                  <li className={style["btnLink"]}>
                    <a href="#Contato" onClick={handleLinkClick}>Contato</a>
                  </li>
                </ul>
              </div>
              <div id={style["btnsTopo"]}>
                <button id={style["btnLogin"]}>
                  <Link to="/login" className={style["btnLink"]}> Login </Link>
                </button>
                <span className={style["linhaMeio"]}></span>
                <button id={style["btnCadastro"]}>
                  <Link to="/cadastro" className={style["btnLink"]}> Cadastro </Link>
                </button>
              </div>
            </>
          )}
        </>
      )}
    </nav>
  );
};

export default NavBar;
