import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from "./Publicacoes.module.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from "../../utils/assets/logo.svg";
import { getId } from '../../services/auth';
library.add(faPaperclip, faPaperPlane);

function Publicacoes({ isOwner }) {
    const [name, setTitle] = useState('');
    const [descricao, setDesc] = useState('');
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [message, setMessage] = useState(null);
    const [posts, setPosts] = useState([]);
    const [visiblePosts, setVisiblePosts] = useState(5);
    const id = getId();
    const url = window.location.pathname; 
    const IdEmpresa = url.substring(url.lastIndexOf("/") + 1); 

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescChange = (event) => {
        setDesc(event.target.value);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
        setImageName(file ? file.name : null);
    };

    const fetchPosts = async () => {
        try {
            const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${IdEmpresa}/publicacao`);

            if (response.ok) {
                const data = await response.json();

                // Busca as imagens associadas a cada publicação
                const postsWithImages = await Promise.all(
                    data.map(async (post) => {
                        const imageResponse = await fetch(
                            `https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${IdEmpresa}/publicacao/${post.idPublicacao}`
                        );
                        const imageData = imageResponse.ok ? await imageResponse.json() : null;
                        console.log('imageData', imageData.avatar)
                        return {
                            ...post,
                            avatar: imageData ? imageData.avatar : null, // Assume que o campo "file" contém a URL da imagem
                        };
                    })
                );

                setPosts(postsWithImages.reverse());
            } else {
                setMessage("Erro ao buscar publicações. Tente novamente.");
            }
        } catch (error) {
            setMessage("Erro ao buscar publicações. Tente novamente.");
        }
    };

    const handleSubmit = async () => {
        if (name.trim() === "" || descricao.trim() === "") {
            setMessage("Título e descrição não podem estar vazios.");
            return;
        }
    
        try {
            // Cria a publicação inicial no MockAPI
            const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}/publicacao`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    titulo: name,
                    descricao: descricao,
                }),
            });
    
            if (!response.ok) {
                setMessage("Erro ao criar a publicação. Tente novamente.");
                return;
            }
    
            const createdPost = await response.json();
            let imageUrl = null;
    
            // Se houver imagem, faz upload para o Cloudinary
            if (image) {
                const formData = new FormData();
                formData.append('file', image);
                formData.append('upload_preset', 'uploadImagens'); // Substitua pelo seu upload_preset do Cloudinary
    
                const cloudinaryResponse = await fetch(
                    `https://api.cloudinary.com/v1_1/dryvtyiwk/upload`, // Substitua 'dryvtyiwk' pelo seu cloud_name
                    {
                        method: 'POST',
                        body: formData,
                    }
                );
    
                if (cloudinaryResponse.ok) {
                    const cloudinaryData = await cloudinaryResponse.json();
                    imageUrl = cloudinaryData.secure_url;
    
                    // Atualiza a publicação no MockAPI com a URL da imagem
                    await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}/publicacao/${createdPost.idPublicacao}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ...createdPost,
                            avatar: imageUrl,
                        }),
                    });
                } else {
                    setMessage("Publicação criada, mas ocorreu um erro ao enviar a imagem.");
                }
            }
    
            // Atualiza os posts no estado
            setPosts((prevPosts) => [
                {
                    ...createdPost,
                    avatar: imageUrl, // Adiciona a URL da imagem, se existir
                },
                ...prevPosts,
            ]);
    
            // Reseta os campos
            setTitle('');
            setDesc('');
            setImage(null);
            setImageName(null);
            setMessage("Publicação enviada com sucesso!");
        } catch (error) {
            console.error(error);
            setMessage("Erro ao enviar a publicação. Tente novamente.");
        }
    };
    
    

    useEffect(() => {
        fetchPosts();
    }, []);

    const handleLoadMore = () => {
        setVisiblePosts(visiblePosts + 5);
    };

    return (
        <div id={style["container"]}>
            {isOwner && (
                <section id={style["areaPublicar"]}>
                    <div className={style["publication-input"]}>
                        <div className={style["logoPost"]}>
                            <img src={Logo} alt="" />
                        </div>

                        <div className={style["textarea-container"]}>
                            <input
                                type="text"
                                className={style["titleInput"]}
                                placeholder="Título da publicação"
                                value={name}
                                onChange={handleTitleChange}
                            />
                            <textarea
                                className={style["textArea"]}
                                placeholder="Escreva a descrição"
                                value={descricao}
                                onChange={handleDescChange}
                            />
                            <div className={style["actions"]}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="fileInput"
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="fileInput" className={style["arquivoButton"]}>
                                    <FontAwesomeIcon icon="paperclip" className={style["attach-button"]} />
                                </label>
                                <button className={style["send-button"]} onClick={handleSubmit}>
                                    <FontAwesomeIcon icon="paper-plane" />
                                </button>
                            </div>
                            {imageName && <p className={style["fileName"]}>{imageName}</p>}
                        </div>
                    </div>
                    {message && <p className={style["message"]}>{message}</p>}
                </section>
            )}
            <section id={style["publicacoes"]}>
                {posts.slice(0, visiblePosts).map((post, index) => (
                    <div key={index} className={style["post"]}>
                        <h3>{post.titulo}</h3>
                        <p>{post.descricao}</p>
                        <div className={style["areaImagem"]}>
                            {post.avatar && <img src={post.avatar} alt="Publicação" className={style["postImage"]} />}
                        </div>
                    </div>
                ))}
            </section>
            {posts.length > visiblePosts && (
                <div className={style["load-more"]}>
                    <button onClick={handleLoadMore}>Carregar Mais</button>
                </div>
            )}
        </div>
    );
}

Publicacoes.propTypes = {
    isOwner: PropTypes.bool.isRequired,
};

export default Publicacoes;
