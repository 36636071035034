import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../../../components/navBar/NavBar';
import Logo from "../../../utils/assets/logo.svg";
import style from "./Perfil.module.css";
import InfoPerfilEmpresa from '../../../components/infoPerfilEmpresa/InfoPerfilEmpresa';
import Publicacoes from '../../../components/publicacoes/Publicacoes';
import Comentarios from '../../../components/comentarios/Comentarios';
import InputField from "../../../components/inputs/Inputs";
import api from "../../../api";
import ImgCriarServico from "../../../utils/assets/ImgCriarServico.svg";
import EditIcon from '@mui/icons-material/Edit';
import { getUsuario, getId, getToken } from '../../../services/auth';
import { Style } from '@mui/icons-material';

const Perfil = () => {
    const { id } = useParams();
    const [empresaInfo, setEmpresaInfo] = useState(null);
    const [error, setError] = useState(false);
    const [userType, setUserType] = useState('');
    const [userId, setUserId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // State para controlar o modal de troca de logo
    const url = window.location.pathname; 
    const IdEmpresa = url.substring(url.lastIndexOf("/") + 1); 
    const [totalAvaliacoes, setTotalAvaliacoes] = useState('');
    const [fotoPerfilUrl, setFotoPerfilUrl] = useState('');

    const token = getToken();

    useEffect(() => {
        const usuario = getUsuario();
        const id = getId();
        setUserType(usuario);
        setUserId(id);
        console.log('Tipo de usuário e id: ', id, usuario);
    }, []);

    const [selectedColor, setSelectedColor] = useState("#000000"); // Cor inicial

    const handleColorChange = (e) => {
        setSelectedColor(e.target.value);
        console.log('Cor selecionada:', e.target.value);

        
    };

    const fetchEmpresaInfo = async () => {
        try {
            const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}`);
            if (!response.ok) {
                throw new Error('Erro ao buscar informações da empresa');
            }
            const data = await response.json();
            setEmpresaInfo(data);
            console.log(data.fotoPerfil)
            setFotoPerfilUrl(data.fotoPerfil);
        } catch (error) {
            console.error('Erro ao buscar informações da empresa:', error);
            setError(true);
            toast.error('Erro ao buscar informações da empresa');
        }
    };

    useEffect(() => {
        if (id) {
            fetchEmpresaInfo();
        }
    }, [id]);

    const [selectedButton, setSelectedButton] = useState('Publicações');

    const [serviceCategory, setServiceCategory] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [serviceTitle, setServiceTitle] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (serviceCategory === '' || serviceDescription === '' || serviceTitle === '') {
            toast.error('Por favor, preencha todos os campos.');
            return;
        }

        try {
            const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${id}/servicos`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nomeServico: serviceTitle,
                    categoriaServico: serviceCategory,
                    descricaoServico: serviceDescription
                }),
            });

            if (!response.ok) {
                throw new Error('Erro ao criar o serviçoss');
            }

            const responseData = await response.json();
            console.log(responseData);

            // Show success toast
            toast.success('Serviço criado com sucesso!');

            // Reset form fields and close modal after successful submission
            setServiceCategory('');
            setServiceDescription('');
            setServiceTitle('');
            setIsModalOpen(false);
        } catch (error) {
            console.error('Erro:', error);

            toast.error('Erro ao criar o serviço');
        }

        let excelenteCount = 0
        let bomCount = 0
        let regularCount = 0
        let ruimCount = 0
        let pessimoCount = 0
        try {
  
          const response = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/empresa/${IdEmpresa}/avaliacoes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
          })
          .then((response) => response.json()) // Converte a resposta para JSON
          .then((data) => {
            if (data) {
              console.log(data)
              
            //   setTotalAvaliacoes(data.length);
          
          // Percorrer o array de avaliações e contar por tipo
          for (let i = 0; i < data.length; i++) {
  
  
            const avaliacaoExcelente = data[i].excelente; // Supondo que o campo que contém a avaliação seja "avaliacao"
            const avaliacaoBom = data[i].bom;
            const avaliacaoRegular = data[i].regular;
            const avaliacaoRuim = data[i].ruim;
            const avaliacaoPessimo = data[i].pessimo;
          
            if (avaliacaoExcelente) {
              excelenteCount++;
            }
            if (avaliacaoBom) {
              bomCount++;
            }
            if (avaliacaoRegular) {
              regularCount++;
            }
            if (avaliacaoRuim) {
              ruimCount++;
            }
            if (avaliacaoPessimo) {
              pessimoCount++;
            }

  
          }
            }
          })
          .catch((e) => {
            console.error("Houve um erro ao buscar a empresa: ", e);
          });
          const total = excelenteCount + bomCount + regularCount + ruimCount + pessimoCount;

          const mediaCalculada = (
            (excelenteCount * 5 + bomCount * 4 + regularCount * 3 + ruimCount * 2 + pessimoCount * 1) /
            total
          );
          console.log(mediaCalculada.toFixed(1));
          setTotalAvaliacoes(mediaCalculada.toFixed(1));

          console.log('totalAvaliacoes', totalAvaliacoes.type)

            const responseAvaliacao = await fetch(`https://6756b0dac0a427baf949dbb4.mockapi.io/servico`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    avatar: fotoPerfilUrl,
                    titulo: serviceTitle,
                    categoria: serviceCategory,
                    descricaoServico: serviceDescription,
                    nota: mediaCalculada.toFixed(1),
                    empresaId: IdEmpresa
                }),
            });
        } catch {

        }
    };

    return (
        <>
        <div id={style["perfilPrincipal"]}>
            <NavBar logoInicio={Logo} />
            <div id={style["areaTopo"]}
            style={{ backgroundColor: selectedColor }}
            >
                <div className={style.editarCor}>
                    {userType === "empresa" && (
                        <>
                            <input
                                type="color"
                                value={selectedColor}
                                onChange={handleColorChange}
                                className={style.colorPicker}
                                title="Escolha uma cor"
                            />
                        </>
                    )}
                </div>
            </div>
        </div>

            {empresaInfo && (
                <InfoPerfilEmpresa
                    logo={empresaInfo.fotoPerfil}
                    nomeEmpresa={empresaInfo.nome}
                    breveDesc={empresaInfo.breveDesc}
                    endereco={empresaInfo.endereco}
                    descricao={empresaInfo.descricao}
                    id={id} // Passando o id da empresa para o InfoPerfilEmpresa
                    setIsModalOpen={setIsModalOpen} // Passando a função para abrir/fechar o modal
                />
            )}

            <section id={style["areaBotoes"]}>
                <a onClick={() => setSelectedButton('Publicações')} href="#Publicacoes" className={`${style["linkBtn"]}  ${selectedButton === 'Publicações' ? style["selected"] : ''}`} >
                    <Link to="" >
                        <button>Publicações</button>
                    </Link>
                </a>

                <a className={`${style["linkBtn"]} ${selectedButton === 'Comentários' ? style["selected"] : ''}`} href="#Comentarios" onClick={() => setSelectedButton('Comentários')}>
                    <Link to="">
                        <button>Comentários</button>
                    </Link>
                </a>

                {userType === 'empresa' && userId === id && (
                    <>
                        <Link className={`${style["linkBtn"]} ${selectedButton === 'Criar Serviço' ? style["selected"] : ''}`} to="" onClick={() => { setSelectedButton('Criar Serviço'); setIsModalOpen(true); }}>
                            <button>Criar Serviço</button>
                        </Link>

                        {isModalOpen && (
                            <div className={style.modalBackground} onClick={() => setIsModalOpen(false)}>
                                <div>
                                    
                                </div>
                                <div className={style.modalImgServico}>
                                    <img src={ImgCriarServico} alt="Imagem criar servico" />
                                </div>
                                <div className={style.modalContent} onClick={e => e.stopPropagation()}>
                                    <h3>Criar Serviço</h3>
                                    <form onSubmit={handleSubmit}>
                                        <label className={style.modalCategoria}>
                                            Categoria do Serviço:
                                            <InputField
                                                type="select"
                                                className={style["modalCategoriaInput"]}
                                                placeholder="Categoria"
                                                value={serviceCategory}
                                                onChange={e => setServiceCategory(e.target.value)}
                                                options={[
                                                    { value: "Refrigeração", label: "Refrigeração" },
                                                    { value: "Moda", label: "Moda" },
                                                    { value: "Tecnologia", label: "Tecnologia" },
                                                    { value: "Domestico", label: "Doméstico" },
                                                ]}
                                            />
                                        </label>
                                        <label className={style.modalTituloServico}>
                                            Título do Serviço:
                                            <input
                                                type="text"
                                                className={style["modalTituloInput"]}
                                                placeholder="Título"
                                                value={serviceTitle}
                                                onChange={e => setServiceTitle(e.target.value)}
                                            />
                                        </label>
                                        <label className={style.modalDescricao}>
                                            Descrição:
                                            <textarea value={serviceDescription} onChange={e => setServiceDescription(e.target.value)} />
                                        </label>
                                        <input type="submit" id={style["btnCriarServico"]} value="Criar Serviço" />
                                    </form>
                                </div>
                            </div>
                        )}
                    </>
                )}


            </section>

            <div id="Publicacoes"></div>
            <Publicacoes isOwner={userType === 'empresa' && userId === id} />

            <div id="Comentarios"></div>
            <Comentarios />

            <ToastContainer />
        </>
    );
};

export default Perfil;
