// Importa a biblioteca Axios.
import axios from "axios";
import { getToken } from "./services/auth";


const api = axios.create({
    baseURL:"54.243.77.44:8080/"
});


export default api;
